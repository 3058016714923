<template>
    <div class="bg-white border-b border-gray-300 fixed top-0 left-0 h-16 w-full z-20">
        <div class="flex h-full justify-between max-w-screen-md mx-auto">
            <div class="h-full p-2">
                <router-link class="h-full"
                    to="/">
                    <img class="h-full"
                        src="https://content.gacorvera.com/gac-logo-64.png">
                </router-link>
            </div>

            <div class="navbar-links flex font-medium h-full items-center text-sm
                md:text-base">
                <router-link to="/projects">
                    Projects
                </router-link>
                <router-link to="/contact">
                    Contact
                </router-link>
                <router-link to="/careers">
                    Careers
                </router-link>
            </div>
        </div>
    </div>
</template>

<style scoped>
.navbar-links a {
    @apply flex px-4 h-full items-center;
}

.navbar-links a + a {
    @apply border-l;
}
</style>
