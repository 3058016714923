<template>
    <div class="mx-auto pt-16">
        <project-single v-if="projectLightboxed !== undefined"
            :project="projectLightboxed">
        </project-single>

        <div v-if="isLoadingProjects" class="h-screen">
            <img class="mx-auto h-full"
                src="/loading.svg">
        </div>
        <div v-else class="max-w-screen-xl mx-auto">
            <project-card :project="projectFeatured"></project-card>
        </div>

        <div class="bg-gray-100 font-bold p-12 py-10">
            <div class="flex flex-col gap-4 items-center justify-center max-w-screen-lg mx-auto text-center
                md:text-justify md:flex-row">
                <h2 class="text-xl">Looking for a general contractor?</h2>
                <router-link class="bg-green-600 font-bold inline-block no-underline px-8 py-4 rounded text-white text-xl"
                    to="/contact">
                    Contact Us!
                </router-link>
            </div>
        </div>

        <div class="max-w-screen-lg mx-auto p-12 py-10 text-center
            md:py-24">
            <h1 class="font-bold mb-6 text-xl">Clients</h1>
            <div class="flex flex-wrap items-center justify-center">
                <div class="p-3 w-1/3 md:w-1/6"
                    v-for="(client, index) in clients" :key="index">
                    <img class="filter grayscale w-full"
                        :src="`https://content.gacorvera.com/clients/${client}.png`"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Source: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
import { useRoute } from 'vue-router'
import meta from './../AppMeta.js'
import ProjectCard from './Project/ProjectCard.vue'
import ProjectSingle from './Project/ProjectSingle.vue'

export default {
    components: {
        ProjectCard,
        ProjectSingle
    },
    computed: {
        projectsCached () {
            return this.$store.state.projects
        },
        projectLightboxed () {
            return this.$store.state.projectLightboxed
        }
    },
    data: () => ({
        clients: [
            'asics',
            'atlassian',
            'audemars-piguet',
            'balenciaga',
            'bang-olufsen',
            'breitling',
            'bulthaup',
            'chopard',
            'franke',
            'fresh',
            'jaeger-le-coultre',
            'leica',
            'longines',
            'loewe',
            'minotti',
            'new-balance',
            'onitsuka-tiger',
            'panerai',
            'patek-philippe',
            'rolex',
            'santoni',
            'seiko',
            'the-nines',
            'tim-hortons',
            'under-armour',
            'vacheron-constantin',
            'victorinox',
        ],
        projects: undefined,
        projectFeatured: undefined,
        route: useRoute(),
        isLoadingProjects: true,
        meta: {
            title: 'G.A. Corvera Construction Corporation | Home',
            tags: [
                {
                    property: 'og:title',
                    content: 'G.A. Corvera Construction Corporation | Home'
                }, {
                    property: 'og:url',
                    content: 'https://gacorvera.com'
                }, {
                    name: 'description',
                    content: 'Website of G.A. Corvera Construction Corporation'
                }, {
                    property: 'og:description',
                    content: 'Website of G.A. Corvera Construction Corporation'
                }, {
                    property: 'og:image',
                    content: 'https://content.gacorvera.com/gac-logo-720.png'
                }
            ]
        }
    }),
    async created() {
        this.projects = this.projectsCached
        this.projectFeatured = this.selectFeatured()
        this.randomizeClients()
        this.isLoadingProjects = false
    },
    mounted() {
        if (this.route.path === '/') {
            meta.update(this.meta)
        }
    },
    methods: {
        randomizeClients() {
            this.clients.sort(() => Math.random() - 0.5)
        },
        selectFeatured() {
            const limit = this.projects.length
            const index = Math.floor(Math.random() * limit)
            return this.projects[index]
        }
    }
}
</script>
